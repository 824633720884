import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { HERO_COMPONENT, PAGE_DESCRIPTION_COMPONENT, SURVEY_OVERVIEW_SECTION_COMPONENT, INFO_LIST_PARAGRAPHS_AND_MEDIA_COMPONENT, OPTINCTA_COMPONENT, FOOTER_COMPONENT } from '../../../constants';
import Hero from '../../molecules/Hero';
import OptInCta from '../../molecules/OptInCta';
import SurveyOverviewSection from '../../molecules/SurveyOverviewSection';
import InfolistParagraphAndMedia from '../../molecules/infolist-paragraphs-and-media';

const SurveyOnboardingComponent = ({ data }) => {
    const surveyOnboardingComponents = [];

    data.surveyOnboardingPage.content.components.forEach(component => {
        if(component.id === HERO_COMPONENT) surveyOnboardingComponents.push(<Hero />);
        if(component.id === SURVEY_OVERVIEW_SECTION_COMPONENT) surveyOnboardingComponents.push(<SurveyOverviewSection />);
        if(component.id === OPTINCTA_COMPONENT) surveyOnboardingComponents.push(<OptInCta />);
        if(component.id === INFO_LIST_PARAGRAPHS_AND_MEDIA_COMPONENT) surveyOnboardingComponents.push(<InfolistParagraphAndMedia />);
    });

  return(
    <>
      {surveyOnboardingComponents.map((component, index) => (
        <div key={index}> {component}</div>
      ))}
    </>
  )
}

export default SurveyOnboardingComponent;