import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import SurveyOnboardingComponent from './SurveyOnboardingComponent';

const SurveyOnboarding = () => {
    const configuration = useSelector((state) => state.configuration);

    useEffect(() => {
        console.log({});
        
    }, [configuration])

    const { surveyOnboardingPage } = configuration;
    if(!surveyOnboardingPage) return null;

    return(<SurveyOnboardingComponent data={configuration} />)
}

export default SurveyOnboarding;