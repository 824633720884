import './index.scss'
import {API} from '../../../constants';
import LazyLoadImage from '../../atoms/image/Image';

const InfolistParagraphAndMedia = ({ data }) => {

  const { object } = data || {};
  const { media, properties } = object || {};
  const { mode } = properties[0] || {};
  const { image } = media[0] || {};
  const { Desktop, Mobile, altText } = image || {};

  return (
    <div  className={`infolistparagaphmedia container py-8 px-4 ${mode === 'information' ? 'text-left md:px-4 lg:px-180 4xl:px-220':'text-center'}`} id={data.id}>
      
      <div className={'flex md:flex-wrap justify-center sm:px-2'}>
          <div className={`w-full ${mode === 'information' && 'md:w-full 4xl:w-9/12'}`}>
            <div className="infolistparagaphmedia__heading font-bold sm:px-3 md:text-3xl">
              <h2>{data.object.heading}</h2>
            </div>
          
            <ul className={`px-3 sm:px-3 sm:py-2 ${mode === 'information' ? 'md:flex 4xl:flex-wrap lg:pb-6':'paragraph-standard-ticks'}`}>
                { data.object.paragraphs.map((paragraph, paragraphIndex) =>
                  <li className={`paragraph-standard ${mode === 'information' ? 'list-disc py-1' :'list-none text-left'}`} key={paragraphIndex}>
                    { mode === 'paragraph' && <LazyLoadImage src={`${API}/images/${Mobile}`} className="logo mx-auto" alt={altText} /> }
                    <p className="paragraph-standard__heading mb-1"><strong>{paragraph.heading}</strong></p>                    
                    <p dangerouslySetInnerHTML={{__html: paragraph.text}}></p>
                  </li>
                )}
            </ul>
          </div>

        {
              Desktop && Mobile && mode === 'information' && <div className="sm:p-5 md:w-full 4xl:w-2/4 flex justify-center"><picture>
              <source media="(min-width:768px)" srcSet={`${API}/images/${Desktop}`}/>
              <LazyLoadImage src={`${API}/images/${Mobile}`} className="logo mx-auto" alt={altText} />
          </picture>
          </div>
        }
        </div>
     </div>
 )
}

export default InfolistParagraphAndMedia;