import ResultsComponent from "../components/organisms/Results";
import { PATH_PAGE_LOCALE, PATH_PAGE_SURVEY_SECTION,DEFAULT_LOCALE, SURVEY } from '../constants';
import { useSelector } from 'react-redux';
import './section.scss';

const Results = ()=> {
    const configuration = useSelector((state) => state.configuration);
    const { survey } = configuration;
    const completedPages = useSelector((state) => state.completedPages);

    if (survey) {
        const cSurvey = survey.content.components.find(x => x.id === SURVEY);
        const sections = cSurvey.object.sections;
        if (completedPages.length != sections.length) {
            const url = `${PATH_PAGE_SURVEY_SECTION}${1}${PATH_PAGE_LOCALE}${DEFAULT_LOCALE}`;
            return window.location.replace(url);
        }
    }

return (
  <div className="survey-wrapper">
      <div className="survey-container">
          {/* <ProgressPoints/> */}
          <div className="survey pt-5 ">
              
              <div className="image-wrapper text-center my-8 ">
                  {/* <img src={ section1 } alt="section-1" className="mx-auto"></img> */}
              </div>

              <div className="fields-wrapper">
              <ResultsComponent />
              </div>
          </div>
      </div>
  </div>
)
}

export default Results