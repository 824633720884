import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import AdditionalCommentsComponent1 from './AdditionalCommentsComponent';
import { useLocation, Link } from 'react-router-dom';
import AdditionalComments from '../../molecules/survey/AdditionalComments';
import { PushDataLayerObject } from '../../../utility';
import { Q_ADDITIONAL_COMMENTS, GILD_EVENT, BUTTONS, CLICKED, GET_YOUR_RESULTS, PATH_PAGE_LOCALE_DEFAULT,PATH_REDIRECT_USER } from '../../../constants';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);
  return props.children
}

const AdditionalCommentsComponent = () => {
  const location = useLocation();
  const configuration = useSelector((state) => state.configuration);
  const [headingState, setHeadingState] = useState({});
  const [additionalCommentsData, setAdditionalCommentsData] = useState({});
  const [getYourResultsCTAData, setGetYourResultsCTAData] = useState({});
  const [showNext, setShowNext] = useState(false);
  const { survey } = configuration;

  const history = useHistory();

  const acceptedTCs = useSelector((state) => state.acceptedTCs);
  
  const url = window.location.href;
  let splitedUrl = url.split('?');
  let locale = splitedUrl[1]?.split('=')[1];

  if(acceptedTCs === false){
    let path = `?locale=${locale ? locale : PATH_PAGE_LOCALE_DEFAULT}`;
    history.push(PATH_REDIRECT_USER + path);
  }

  React.useEffect(() => {
    if (survey) {
      const cAdditionalCommentsIndex = survey.content.components.findIndex(x => x.id === Q_ADDITIONAL_COMMENTS);
      const cAdditionalComments = survey.content.components[cAdditionalCommentsIndex];
      const getYourResultsCTA = survey.content.components[cAdditionalCommentsIndex + 1];
      setAdditionalCommentsData(cAdditionalComments);
      setGetYourResultsCTAData(getYourResultsCTA);
      setHeadingState({
        name: cAdditionalComments?.heading,
        subHeading: cAdditionalComments?.subHeading
      });
    }

  }, [location, configuration, additionalCommentsData]);

  if (!survey) return null;

  const onValueChange = (value) => {
   
    const additionalCommentObject = Object.assign({}, {...additionalCommentsData});

    if(value.length > 0) {
      additionalCommentObject.object.answer = {
        text: value,
        answered: 'true'
      }
    } else {
      additionalCommentObject.object.answer = {
        text: '',
        answered: 'false'
      }
    }

    setAdditionalCommentsData(additionalCommentObject);

  }

  const handleNext = () => {
    PushDataLayerObject(
      configuration,
      GILD_EVENT,
      BUTTONS,
      CLICKED,
      GET_YOUR_RESULTS);

    setShowNext(false);
  }

  return (
    <_ScrollToTop>
      <div>
        <AdditionalCommentsComponent1 data={headingState} />
        <div>
          {
              <div className="mx-auto mb-20 max-w-3xl md:max-w-3xl lg:max-w-3xl 2xl:max-w-3xl 3xl:max-w-3xl md:mx-auto lg:mx-auto 2xl:mx-auto 3xl:mx-auto px-8 sm:px-10">
                  {
                      <AdditionalComments data={additionalCommentsData} onValueChange={onValueChange}/>                  
                  }
              </div>  
          }
          <div className="next-btn-wrapper flex w-full text-center py-10 justify-center">
            <Link onClick={handleNext} className="w-1/5 sm:w-1/2 md:w-1/2 lg:w-1/3 py-3 rounded-full btn-primary font-semibold text-poppins text-base block"
              to={getYourResultsCTAData.object? getYourResultsCTAData.object.link : '#'}>
              {getYourResultsCTAData?.object?.text}
          </Link>
          </div>
        </div>
      </div>
    </_ScrollToTop>
  )
}

export default AdditionalCommentsComponent;