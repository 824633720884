export const API = `${process.env.REACT_APP_API_BASE_URL}:${process.env.REACT_APP_API_PORT}`;
export const DEFAULT_LOCALE = `${process.env.REACT_APP_DEFAULT_LOCALE}`;
export const PATH_PAGE_LOCALE = `/?locale=`;
export const PATH_PAGE_LOCALE_DEFAULT = `${process.env.REACT_APP_DEFAULT_LOCALE}`;
export const PATH_PAGE_LANDING = '/';
export const PATH_PAGE_SURVEY_ONBOARDING = '/survey-onboarding/';
export const PATH_PAGE_SECTION = 'section/';
export const PATH_PAGE_SURVEY_RESULT = `/survey/results/`;
export const PATH_PAGE_SURVEY_SECTION = `/survey/section/`;
export const PATH_PAGE_SURVEY_ADDITIONAL_COMMENTS = `/survey/additional-comments`;
export const PATH_PAGE_FEEDBACK = `/feedback`;
export const PATH_PAGE_REDIRECT1 = `/lapaz`;
export const PATH_PAGE_REDIRECT2 = `/apoyopositivo`;
export const PATH_REDIRECT_USER = `/`;

//Error codes
export const BAD_REQUEST_ERROR_CODE = 400;
export const INTERNAL_SERVER_ERROR_CODE = 500;

//KEY Events Codes
export const ENTER_KEY_CODE = 13;
export const SPACE_KEY_CODE = 32;

//Page Components
export const HERO_COMPONENT = 'c-hero';
export const PAGE_DESCRIPTION_COMPONENT = 'c-page-description';
export const ANCHOR_COMPONENT = 'c-cta';
export const OPTINCTA_COMPONENT = 'c-opt-in';
export const INFO_LIST_STEPS_COMPONENT = 'c-infolist-steps';
export const INFO_LIST_TICK_BULLETS_COMPONENT = 'c-infolist-tick-bullets';
export const INFO_LIST_PARAGRAPHS_AND_MEDIA_COMPONENT = 'c-infolist-paragraphs-and-media';
export const SURVEY = 'c-survey';
export const SURVEY_OVERVIEW_SECTION_COMPONENT = 'c-survey-snapshot';
export const FOOTER_COMPONENT = 'c-footer';

//Question Components
export const Q_CATEGORIES = ['Categories','Psychological','Social','Health','Functional','Medication'];
export const Q_MCQ = 'q_mcq';
export const Q_YES_NO = 'q_yes_no';
export const YES_NO_WITH_MULTIPLE_TEXT = 'q_yes_no_with_text_multiple';
export const Q_YES_NO_WITH_MULTIPLE_TEXT = 'q_yes_no_with_text_multiple';
export const Q_YES_NO_WITH_TEXT = 'q_yes_no_with_text';
export const Q_RATING = 'q_rating';
export const Q_ADDITIONAL_COMMENTS = 'c-additional-comments';

export const Q_FEEDBACK = 'c-survey-feedback';
export const Q_FEEDBACK_RATING = 'q_feedback_rating';
export const Q_FEEDBACK_YES_NO = 'q_feedback_yes_no';

export const ANSWER= `answer_`;

//Fonts for PDF
export const REACT_APP_PDF_FONT_REGULAR = `${process.env.REACT_APP_PDF_FONT_REGULAR}`;
export const REACT_APP_PDF_FONT_BOLD = `${process.env.REACT_APP_PDF_FONT_BOLD}`;

//Cookie Banner Key
export const REACT_APP_KEY_COOKIE_SCRIPT = `${process.env.REACT_APP_KEY_COOKIE_SCRIPT}`;

//GTM - Google Tag Manager
export const REACT_APP_GTM_ID = `${process.env.REACT_APP_GTM_ID}`;
export const APP_GTAG_GUAID=`${process.env.REACT_APP_GTAG_GUAID}`;
export const APP_GTAG_SCRIPT_CLASS_NAME= 'optanon-category-C0002';

//GTM - Default settings only for use in case Config has no defaults
export const APP_LANGUAGE=`${process.env.REACT_APP_LANGUAGE}`;
export const APP_SUBJECT=`${process.env.REACT_APP_SUBJECT}`;
export const APP_INTENDEDAUDIENCE=`${process.env.REACT_APP_INTENDEDAUDIENCE}`;

//GTM Custom Events (Types)
export const GILD_EVENT = "gild.event";
export const GILD_PAGEVIEW = "gild.pageview";

//GTM Categories
export const BOX_T_AND_C_AND_PP = "Opt-in T&C & PP";
export const INTERNAL_LINKS = "Internal Links";
export const BUTTONS = "Buttons";
export const SURVEY_SECTION = "Survey-Section";

//GTM Actions
export const ACCEPTED = "Accepted";
export const CLICKED = "Clicked";
export const ANSWERED = "Answered";

//GTM Labels
export const ACCEPTING_TERM_AND_CONDITIONS_AND_PRIVACY_POLICY = "Accepting T&C & PP";
export const SURVEY_OVERVIEW = "01. Survey Overview";
export const START_THE_SURVEY = "11. Start the survey";
export const SURVEY_PAGE_NAME = "survey";
export const ANSWERING_QUESTION = "Q";
export const NEXT_SECTION = "21. Next - Section";
export const NEXT_ADDTIONAL_COMMENTS = "31. Next - Additional Comments";
export const NEXT_SURVEY_RESULTS = "32. Next - Survey Results";
export const GET_YOUR_RESULTS = "41. Get Your Results";
export const DOWNLOAD_REPORT = "51. Download Report";
export const SUBMIT_FEEDBACK = "61. Submit Feedback Form";
export const EVENT_LABEL_MESSAGE = "Feedback -";
export const SEEING = "Seeing";
export const TERM_AND_CONDITION = "T&C";
export const PRIVACY_POLICY = "Privacy Policy";
export const COOKIE_POLICY = "Cookie Policy";
export const CONTACT_US = "Contact Us";