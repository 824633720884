import React from 'react';
import Breadcrumb from '.';
import { useSelector } from 'react-redux';
import './index.scss';

function BreadcrumbComponent() {
  const pages = useSelector((state) => state.surveyPages.pages);
  const currentSectionNumber = useSelector((state) => state.currentSectionNumber);

  let currentPage = currentSectionNumber - 1;
  let crumbs = [];

  pages.forEach((x, index) => {
    crumbs.push(index + 1);
  });

  return (
    <div className="header section-breadcrumb pt-12 sm:pt-6 md:pt-12 lg:pt-12 2xl:pt-12 3xl:pt-12 px-20 sm:px-10 md:px-20 xl:px-20 lg:px-20 2xl:px-20 3xl:px-20 4xl:px-20">
      <Breadcrumb crumbs={crumbs} currentSectionNumber={currentPage} />
    </div>
  );
}

export default BreadcrumbComponent;