import SurveyOnboarding from '../components/organisms/SurveyOnboarding';

const SurveyOnboardingPage = ()=> {
    return (
        <div className="max-w-screen-2xl mx-auto">
          <SurveyOnboarding />
        </div>  
    );
}

export default SurveyOnboardingPage;

