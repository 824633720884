import { Fragment, useEffect, useState } from "react";
import { IoAlertCircleSharp } from "react-icons/io5";

const Rating = ({question, questionIndex, onValueChange, register, componentId, questionCountInPage, questionNumberLabels, questionIndexer}) => {
  
    const [answerIndex, setAnswerIndex] = useState(question.answer.findIndex(p => p.answered === 'true') === -1 ? parseInt(question.answer.length/2): question.answer.findIndex(p => p.answered === 'true'));
    const [hasChanged, setHasChanged] = useState(question.answer.findIndex(p => p.answered === 'true') !== -1);

    const handleOnChange = (e, index, answerIndex) => { 
      setHasChanged(true);
      setAnswerIndex(answerIndex+1);
      onValueChange(e, index, answerIndex+1)
    };
    const { mainIndex } = questionIndexer || {};
    const handleOnNumberClick= (e, answerIndex) => { 
      document.getElementById(`q_rating${questionIndex}`).value = answerIndex;
      handleOnChange(e, questionIndex, answerIndex);
    };

    const {  properties: questionProperties } = question;
    const { errorMessage: questionMessage } = questionProperties[0] || {};
  
    return (
      <Fragment>
        {question.isValid === false && <div className="error-invalid"><span><IoAlertCircleSharp/></span>{ questionMessage }</div>}
        <div className={"card " + (question.isValid === false ? 'in-valid' : '')} id={ componentId }>
        <span className="card__numbers text-center sm:text-left text-xs font-semibold">{ questionNumberLabels[2].text } {mainIndex} { questionNumberLabels[3].text } {questionCountInPage}</span>
          <label className={"rating-container control-label "+(hasChanged ? 'has-changed':'')} htmlFor={`answer_${questionIndex}`} ><h2 className="question-title sm:text-left text-poppins text-app-900 text-xl font-semibold">{ questionNumberLabels[2].text }{mainIndex}. &nbsp;{question.question}</h2><br />
            <ul role="list" className="rating-field">
                <li role="listitem" key={questionIndex} className="rating-field__li`">
                  <input
                    id={`q_rating${questionIndex}`} 
                    type="range"
                    min={0} 
                    max={question.answer.length-1}
                    step="1"
                    name={`answer_${questionIndex}`} 
                    list={`tickmarks_${questionIndex}`} 
                    defaultValue={answerIndex}
                    onChange={(e) => handleOnChange(e, questionIndex, question.answer.findIndex(p => p.text.split(" ")[0] === document.getElementById(`q_rating${questionIndex}`).value))}
                    onClick={(e) => handleOnChange(e, questionIndex, question.answer.findIndex(p => p.text.split(" ")[0] === document.getElementById(`q_rating${questionIndex}`).value))}
                    onTouchEnd={(e) => handleOnChange(e, questionIndex, question.answer.findIndex(p => p.text.split(" ")[0] === document.getElementById(`q_rating${questionIndex}`).value))}  
                    ref={register({ œ: true })} 
                    className="slider rating-input rounded-lg appearance-none bg-gray-100 w-full" 
                    autoComplete="off"
                  />
                
                <ul role="list" className={'rating-numbers-menu text-xl font-semibold pl-2 pr-1'}>
                  {question.answer.map((answerItem, answerItemIndex) =>
                      <li role="listitem" 
                      onClick={(e) => handleOnNumberClick(e, answerItemIndex)}
                      onChange={(e) => handleOnNumberClick(e, answerItemIndex)}
                      onTouchEnd={(e) => handleOnNumberClick(e, answerItemIndex)}  
                      key={`q_rating${answerItemIndex}`} className={'rating-numbers-list '+(answerItemIndex===answerIndex + 1 && hasChanged? 'text-gray-800':'')}>{answerItem.text.split(" ")[0]}
                      </li>
                  )}
                </ul>
                <ul role="list" className={'rating-text-menu'}>
                      <li role="listitem" className={'rating-text-list text-xs'}>{question.properties[0].startLabel}</li>
                      <li role="listitem" className={'rating-text-list text-xs text-right'}>{question.properties[0].endLabel}</li>
                </ul>
                </li>
            </ul>
            
          </label>
        </div>
      </Fragment>
    )
  }

  export default Rating;