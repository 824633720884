import React from 'react';
import './index.scss'
import Heading from "../../atoms/heading/Heading";
import { API } from '../../../constants';
import LazyLoadImage from '../../atoms/image/Image';

const SectionComponent = ({ data, questionNumberLabels }) => {
  const { Desktop, Mobile, altText } = data.image || {};

  return( data &&
    <div className="text-center section-heading">
      <Heading> {data.text} </Heading>
      <p> {data.description} </p>
    </div>
  )
}

export default SectionComponent;