import { Fragment, useState } from "react";
import { IoCheckmark, IoAlertCircleSharp } from "react-icons/io5";
import { ANSWER, Q_MCQ, Q_CATEGORIES} from '../../../../constants';

export const Selected = ({ answerItem, questionIndex, answerIndex, handleOnChange, register, className, questionCountInPage }) => {
  const [style, setStyle] = useState('')
  const groupedIndex = (questionIndex + 1) - questionCountInPage + 1;
  const groupedQuestion = (questionCountInPage) + "." + groupedIndex;

  const checkAnswerStatus = (answerItem) => {
    if (answerItem.answered === 'true') {
      setStyle(' selected_label_color')
    } else {
      setStyle(' unselected_label_color')
    }
  }

  const removeClass = () => {
    setStyle('')
  }

  return (
    <>
      <input
        id={`${Q_MCQ}${questionIndex}_${answerIndex}`}
        type="radio"
        name={`${ANSWER}${questionIndex}`}
        value={answerItem.text}
        onClick={(e) => { setStyle(' selected_label_color'); handleOnChange(e, questionIndex, answerIndex, questionCountInPage ? true : false, questionCountInPage ? groupedQuestion : -1) }}
        defaultChecked={answerItem.answered === 'true'}
        ref={register({ required: true })}
        className="radio"
        autoComplete="off"
        onBlur={() => { removeClass() }}
      />
      <label onMouseLeave={() => { removeClass() }} onMouseEnter={() => { checkAnswerStatus(answerItem) }}
        className={className + style} htmlFor={`${Q_MCQ}${questionIndex}_${answerIndex}`}>
        {answerItem.text}
        <span className="flex-wrap-reverse place-self-end self-end flex-col"><IoCheckmark /></span>
      </label>
    </>
  )
}

const MCQ = ({ question, questionIndex, onValueChange, register, componentId, questionCountInPage, questionCountInGroup, questionIndexInGroup, questionNumberLabels, questionIndexer }) => {

  const handleOnChange = (e, index, answerIndex, isGrouped = false, questionNumberInGroup = -1) => {
    
    if (isGrouped === true && questionNumberInGroup > -1) {
      onValueChange(e, index, answerIndex, null, null, false, true, questionNumberInGroup)
    } else {
      onValueChange(e, index, answerIndex)
    }
  };
  const { mainIndex, subString } = questionIndexer || {};
  const { properties } = question || {};

  const { errorMessage } = properties[0] || {};

  const qColour = "q-" + Q_CATEGORIES[properties[0].category];

  return (
     <div className={qColour}>
      {question.isValid === false && <div className="error-invalid"><span><IoAlertCircleSharp/></span>{ errorMessage }</div>}
      <ul role="list" className={"card " + (qColour) + " " + (question.isValid === false ? 'in-valid' : '')} id={`${componentId}`}>
      
      { properties[0].grouped === 'false' &&
        <>
      <span className="card__numbers text-center sm:text-left text-xs font-semibold">{ questionNumberLabels[2].text } {mainIndex} { questionNumberLabels[3].text } {questionCountInPage}</span>
        <label className="control-label" htmlFor={`${ANSWER}${questionIndex}`} ><span><h2 className="question-title text-left sm:text-left text-poppins text-app-900 text-xl font-semibold">{ questionNumberLabels[2].text }{mainIndex}. &nbsp; {question.question}</h2></span>
        
          {question.answer.map((answerItem, answerIndex) =>
            <li role="listitem" key={answerIndex} className="mutliple-choice-fields">
              {
                answerItem.answered === 'true' ? <Selected answerItem={answerItem} questionIndex={questionIndex} answerIndex={answerIndex} handleOnChange={handleOnChange} register={register} className='label-selected text-center flex-col-reverse sm:text-left' />
                  : <Selected answerItem={answerItem} questionIndex={questionIndex} answerIndex={answerIndex} handleOnChange={handleOnChange} register={register} className='label-unselected text-center sm:text-left' />
              }
            </li>
          )}
        </label>
        </>
      }

    { properties[0].grouped === 'true' &&
        <>
          {properties[0].groupQuestion.length > 0 && (<span className="card__numbers text-center sm:text-left text-xs font-semibold">{ questionNumberLabels[2].text } {mainIndex} { questionNumberLabels[3].text } {questionCountInPage}</span>)}
            <label className="control-label" htmlFor={`${ANSWER}${questionIndex}`} >
              
            {properties[0].groupQuestion.length > 0 && (<h2 className="grouped-question-title text-center sm:text-left text-poppins text-app-900 text-xl font-semibold">{properties[0].groupQuestion}</h2>)}

              <span className="card__group__numbers text-center text-xs font-semibold">{mainIndex}.{questionIndexInGroup + 1} { questionNumberLabels[3].text } {mainIndex}.{questionCountInGroup}</span>

              <h2 className="question-title text-center text-poppins text-app-900 text-xl font-semibold">{question.question}</h2><br/>
  
              {question.answer.map((answerItem, answerIndex) =>
                <li role="listitem" key={answerIndex} className="mutliple-choice-fields"> 
                 {
                   answerItem.answered === 'true' ? <Selected subString={subString} questionCountInPage={questionCountInPage} answerItem={answerItem} questionIndex={questionIndex} answerIndex={answerIndex} handleOnChange={handleOnChange} register={register} className='label-selected text-center flex-col-reverse sm:text-left' />
                     : <Selected subString={subString} questionCountInPage={questionCountInPage} answerItem={answerItem} questionIndex={questionIndex} answerIndex={answerIndex} handleOnChange={handleOnChange} register={register} className='label-unselected text-center sm:text-left' />
                 }
               </li>
              )}
            </label>
        </>
      }
      </ul>
    </div>
  )
}

export default MCQ;