import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PDFDownloadLink, Document, Page, Text, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useLocation, Switch } from 'react-router-dom';
import generatePDFDocument from './generatePdfDocument';
import { PushDataLayerObject } from '../../../utility';
import { SURVEY, Q_ADDITIONAL_COMMENTS, PATH_REDIRECT_USER,PATH_PAGE_FEEDBACK, GILD_EVENT ,BUTTONS,CLICKED, DOWNLOAD_REPORT, PATH_PAGE_LOCALE, API, Q_CATEGORIES } from '../../../constants';
import './index.scss'

function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scroll({
          top: 0, 
          left: 0, 
          behavior: 'smooth'
        });
    }, [pathname]);
    return props.children
  }

const DownloadReportCTA = ({ data, page }) => {

    const { object } = data || {}; //JSON config of the component
    const acceptedTCs = useSelector((state) => state.acceptedTCs);
    const configuration = useSelector((state) => state.configuration);
    const history = useHistory();
    const [resultsState, setResultsState] = useState([]);
    const [additionalCommentsState, setAdditionalCommentsState] = useState('');
    const dispatch = useDispatch();
  
    const redirectIfNonAcceptedTCs = () => {
        if(acceptedTCs === false){
            history.push(PATH_REDIRECT_USER);
        }
    }
    
    const { survey } = configuration;
    const { surveyResultsPage } = configuration || {};

    const { content } = surveyResultsPage || {};
    const { pdfMeta } = content || {};

    useEffect(() => {
        redirectIfNonAcceptedTCs();

        let isMounted = true; // note this flag denote mount status
            if (isMounted) {
                if(survey) {
                const cSurvey = survey.content.components.find(x=> x.id === SURVEY);
                const cAdditionalComments = survey.content.components.find(x=> x.id === Q_ADDITIONAL_COMMENTS);
                // const results = cSurvey.object.results
                const results = cSurvey.object.sections;
                setResultsState(results);
                setAdditionalCommentsState(cAdditionalComments);
            }
        }
        isMounted = false;
    }, [configuration]);

    // Render this component only if the survey data is available
    if(!survey) return null;

    var styled = StyleSheet.create({
        hidden: {
            display:'none'
        },
        result: {
            textAlign: 'left',
            border: '2px solid white',
            padding: '10px'
        },
        section: {
            color: 'red'
        },
        question: {
            fontWeight: 'bold',
            textTransform: 'uppercase'
        },
        data: {
            color: '#8ef6fc'
        },
        message: {
            color:'#fafc8e'
        },
        flexContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            backgroundColor: "#F3F3F3",
            marginTop: "50px"
        },
        flexRow: {
            flex: "1 50%",
            textAlign: "left",
            padding: "50px 50px 0px 50px"
        },
        resultImage: {
            position: 'absolute',
            top: "48px",
            left: "15px"
        }
      });

    const url = window.location.href;
    let splitedUrl = url.split('?');
    let locale = splitedUrl[1]?.split('=')[1];
    let localeUrl = `${PATH_PAGE_LOCALE}${locale}`;
    let categoriesWithScores = [];
    let medicationQuestionsCount = 0;
    let answer = "";

    const logGA = () => {
        PushDataLayerObject(configuration, GILD_EVENT, BUTTONS, CLICKED, DOWNLOAD_REPORT)
    }

    const blobResultsStateData = new Blob(['{ "survey": ' + JSON.stringify(resultsState) + ', "additionalComments":' + JSON.stringify(additionalCommentsState) + '}'],{type:'application/json'});
    const hrefResultsStateData = URL.createObjectURL(blobResultsStateData);

    const resultsTierContent = survey.content.components.find(x=> x.id === SURVEY).object.results;
    const resultAdditionalComments = resultsTierContent.find(x =>x.id === 'additionalComments').content;
    return (
        <_ScrollToTop>
            <Fragment>
            <div className='cta-div text-center results-survey'>
                <div className='results-highlights'>
                    <div className='results-highlights-container' style={styled.flexContainer}>
                    {(resultsState || []).map((section, sectionIndex) => {
                        let sectionTier = "T1";
                        let sectionTierImage = API+'/images/'+resultsTierContent.find(x => x.id === sectionTier.toLowerCase()).content;
                        return (
                            
                            <div key={sectionIndex} style={styled.flexRow}>
                                <div className='report-comments'>
                                    <p><strong>{section.name}</strong></p>
                                    <div key={sectionIndex} className='report-comment'>
                                        {
                                        section.questions.map((quest, questIndex) => {
                                            const answer = quest.answer.find(x =>x.answered === 'true');
                                            const message = answer && ( answer.score === 'T2' || answer.score === 'T3')? "- " + answer.scoreMessage: "";
                                            if (answer.score === 'T2' && sectionTier === "T1")
                                            {
                                                sectionTier = answer.score;
                                                sectionTierImage = API+'/images/'+resultsTierContent.find(x => x.id === sectionTier.toLowerCase()).content;
                                            }
                                            if (answer.score === 'T3' && sectionTier !== "T3")
                                            {
                                                sectionTier = answer.score;
                                                sectionTierImage = API+'/images/'+resultsTierContent.find(x => x.id === sectionTier.toLowerCase()).content;
                                            }
                                            if(!answer) return true;

                                            return (
                                                (answer.score !== "T1") ? 
                                            <div key={questIndex}>
                                                <p>{message}</p>
                                            </div>
                                            : ""
                                            )
                                        })
                                        } 
                                        {(sectionTier === "T1")? <p>- {resultsTierContent.find(x => x.id === "pass").content}</p>:""}
                                    </div>
                                </div>
                                <img style={styled.resultImage} src={sectionTierImage} alt="" />

                                <div style={styled.hidden}>
                                    {/* Below code is for future - Tier based messages. */}
                                    <div style={styled.result}><strong>{section.name}3234432</strong></div>
                                    <div key={sectionIndex}>
                                        {
                                        section.questions.map((quest, questIndex) => {
                                            const answer = quest.answer.find(x =>x.answered === 'true');
                                            const message = answer && (answer.score === 'T1' || answer.score === 'T2' || answer.score === 'T3')? "-" + answer.scoreMessage: "";
                                            
                                            if(!answer) return true;

                                            return (
                                            <div key={questIndex} style={styled.result}>
                                                <p>Question: {quest.question}</p>
                                                <p>Answer: {answer.text}</p>
                                                <p>MESSAGE: {message}</p>
                                            </div>
                                            )
                                        })
                                        } 
                                    </div> 
                                </div>
                            </div>
                        ) 
                        })}
                        {
                            additionalCommentsState &&
                            <div style={styled.flexRow}>
                                <div className='report-comments'>
                                    <p><strong>{additionalCommentsState?.heading}</strong></p>
                                    <div>
                                        <div className='report-comment'>
                                            <p>{(additionalCommentsState?.object?.answer?.text?.trim()) ? additionalCommentsState?.object?.answer?.text: "- " + resultAdditionalComments}</p>
                                        </div>
                                    </div>
                                </div>
                                <img style={styled.resultImage} src={ API+'/images/'+resultsTierContent.find(x =>x.id === 't1').content} alt="" />
                            </div>
                        }
                        
                    </div>
                </div>
                {/* Method 2 - START - for Results based on scoring in categories */}
                {
                    Q_CATEGORIES.map((category, categoryIndex) => {
                        categoriesWithScores.push({id:category, scores:""});
                        return (
                            <div key={categoryIndex}></div>
                        )
                    })
                }
                <div className='results-highlights2'>
                    <div className='results-highlights2-container'>
                    {(resultsState || []).map((section, sectionIndex) => {

                        var totalSurveyScore = 0;
                        var totalQuestions = 0;
                        var totalSurveyAverage = 0;
                        
                        return (
                            <div key={sectionIndex}>
                                <div className='report-comments'>
                                    <h2>{section.name}</h2>

                                    {/* Populate scores for the categories for each question */}
                                    {
                                        section.questions.map((quest, questIndex) => {

                                            const answer = quest.answer.find(x =>x.answered === 'true');
                                            
                                            categoriesWithScores[quest.properties[0].category].scores = categoriesWithScores[quest.properties[0].category].scores === "" ? answer.score.toLowerCase() : categoriesWithScores[quest.properties[0].category].scores + "," + answer.score.toLowerCase();
                                            totalSurveyScore = totalSurveyScore === "0" ? answer.score.toLowerCase() : parseInt(totalSurveyScore) + parseInt(answer.score.toLowerCase())
                                            if (answer.score > 0) totalQuestions++;
                                            return(
                                                <div key={questIndex} className="hide">
                                                       {quest.properties[0].category}:{answer.score} 
                                                </div>  
                                            )
                                        })
                                    }
                                    {/* Test display Populated Categories */}
                                    {categoriesWithScores.map((categoryScoreItem,categoryScoreItemIndex) => {
                                        return (
                                            <div key={categoryScoreItemIndex} className="hide">
                                                {categoryScoreItem.id}:{categoryScoreItem.scores}
                                            </div>
                                            )
                                        })
                                    }
                                    <div>&nbsp;</div>
                                    {/* Average Scores */}                                    
                                    <div className='category-container'>
                                        {categoriesWithScores.map((categoryScore,categoryScoreIndex) => {
                                            let totalScore = 0;
                                            let individualScores = categoriesWithScores[categoryScoreIndex].scores.split(",");
                                            let averageScore = 0;
                                            for (var i = 0; i < individualScores.length; i++) {
                                                totalScore += parseFloat(individualScores[i]);
                                            }
                                            averageScore = totalScore/individualScores.length;
                                            
                                            totalSurveyAverage = totalSurveyScore/totalQuestions;
                                            return (
                                                parseFloat(totalSurveyAverage).toFixed(2) > 0 && categoryScoreIndex !== 0 && categoryScoreIndex !== 5 && categoryScore?.scores?.length > 0 &&                                              
                                                <div className={`category-item category-item-${categoryScore.id}`} key={categoryScoreIndex}>
                                                    <div className="hide">{categoryScore.id}:{categoryScore.scores}:{totalScore}:{averageScore.toFixed(2)}</div>
                                                    <div><img src={`${API}/images/score-${categoryScore.id}.svg`} alt={categoryScore.id} /></div>
                                                    <div className="category">{categoryScore.id}</div>
                                                    <div className="score">{averageScore.toFixed(2)}</div>
                                                </div>                                                
                                                )
                                            })
                                        }
                                        {
                                        parseFloat(totalSurveyAverage).toFixed(2) > 0  && 
                                        <div className='category-item category-overall'>
                                            <div className='category'>Overall Score</div>
                                            <div className='score'>{parseFloat(totalSurveyAverage).toFixed(2)}</div>
                                            <div className='hide'>Survey Total Score: {totalSurveyScore} / Survey Total Questions{totalQuestions}</div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {/* Medication Questions count */}               
                    {(resultsState || []).map((section, sectionIndex) => {
                        medicationQuestionsCount = 0
                        return (                                                                                  
                            <div className="hide" key={sectionIndex}>
                                {
                                section.questions.map((quest, questIndex) => {
                                    return(
                                        quest.properties[0].category === "5" && <div key={questIndex}>
                                            {medicationQuestionsCount++}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}  
                    {/* Display Medication Questions and answers if submitted */}
                    {(resultsState || []).map((section, sectionIndex) => {
                        medicationQuestionsCount = 0
                        return (
                            <Fragment key={sectionIndex}>
                            {(section.questions.map((quest1, questIndex1) => {
                                answer = quest1.answer.find(x =>x.answered === 'true');
                                return(<div className='hide' key={questIndex1}>{answer.text.length > 0 && quest1.properties[0].category === "5" && <div>
                                            {medicationQuestionsCount++}
                                        </div>}</div>)
                            }))}
                            {(medicationQuestionsCount > 0 && <div>
                                <div className='category-MedicationReport'>
                                    <div>
                                        <h2 className="font-bold sm:px-3 md:text-3xl"><img src={`${API}/images/score-${categoriesWithScores[5].id}.svg`} alt={categoriesWithScores[5].id} /><strong>{categoriesWithScores[5].id}</strong></h2>
                                    </div>    
                                    {
                                        section.questions.map((quest, questIndex) => {
                                            answer = quest.answer.find(x =>x.answered === 'true');
                                            return(
                                                quest.properties[0].category === "5" && <div key={questIndex}>                                                    
                                                    <div className="medicalQuestion">{quest.question}<p>–&nbsp;<i>{answer.text}</i></p></div>
                                              </div>  
                                            )
                                        })
                                    }
                                </div>
                            </div>)}
                            </Fragment>
                        )
                    })}                    
                    </div>
                </div>
                
                {/* Method 2 - END - for Results based on scoring in categories */}                
            </div>
            <div className='cta-download-results text-center'>
                {/* Download Button - START */}
                <p className='results-download-json'>{object.dataObjectLink?.textBeforeLink} <a href={hrefResultsStateData} download={pdfMeta.pdfFileName.replace(".pdf","-Data.json")} className="link underline">{object.dataObjectLink?.textLink}</a> {object.dataObjectLink?.textAfterLink}</p>
                <button onClick={() => { logGA(); generatePDFDocument({ pdfMeta }, { resultsState }, { additionalCommentsState }, `${pdfMeta.pdfFileName}`); history.push(PATH_PAGE_FEEDBACK + localeUrl) }}
                className="btn-download place-content-center text-center bg-primary-900 hover:bg-primary-600 transition-colors duration-200 rounded-full font-semibold py-4 px-6 xs:min-w-17 2xs:min-w-20 3xs:min-w-22 inline-flex cta"
                >
                {object.text}
                </button>
                {/* Download Button - END */}
            </div>    
        </Fragment>
        </_ScrollToTop>
    )    
}

export default DownloadReportCTA;